<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="12" md="6">
        <b-card>
          <div slot="header"><i class="icon-shield"></i> Automod</div>
          <div v-if="loading">
            <div class="sk-circle-fade float-left">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <h2 class="float-left ml-4">Loading automod...</h2>
          </div>
          <div v-else>
            <h4 class="smalltitle">General Settings</h4>
            <p>
              Members with
              <code>manage server</code> or <code>admin</code> are always
              immune. Keep this in mind when trying out automod settings.
              Automod is one of the most commonly used features of the bot and
              bugs are extremely rare, please double check your settings with
              <code>!am</code> and check your discord permissions.
            </p>
            <hr />
            <b-form-group label="Log actions to" label-for="log_channel_select">
              <multiselect
                v-model="log_channel"
                id="log_channel_select"
                track-by="id"
                label="name"
                placeholder="Please select a channel"
                open-direction="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="false"
                :options="groupedChannels"
                :allow-empty="true"
                group-values="channels"
                group-label="category"
                :group-select="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <font-awesome-icon style="color:#72767d" icon="hashtag" />
                    <span class="option__title ml-2">{{
                      props.option.name
                    }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group>
              <label for="drama_channel_select">Drama channel</label>
              <sup id="owo_whats_this" style="color: #999; margin-left:5px"
                >what's this?</sup
              >
              <b-popover
                triggers="hover"
                placement="top"
                target="owo_whats_this"
              >
                (Premium only) Set your punishment to 'post to drama channel'
                and have the context show up in this drama channel. Mods can
                then use predefined reactions to take action. See the faq part
                of the docs for more information.
                <br />
                <b-img src="https://i.imgur.com/kQpdGcX.png" width="250px" />
              </b-popover>
              <multiselect
                v-model="drama_channel"
                id="discord_channel_select"
                track-by="id"
                label="name"
                placeholder="Please select a channel"
                open-direction="bottom"
                :clear-on-select="false"
                :close-on-select="true"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="false"
                :options="groupedChannels"
                :allow-empty="true"
                group-values="channels"
                group-label="category"
                :group-select="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <font-awesome-icon style="color:#72767d" icon="hashtag" />
                    <span class="option__title ml-2">{{
                      props.option.name
                    }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group label="Muterole" label-for="mute_role_select">
              <multiselect
                v-model="muterole"
                id="mute_role_select"
                track-by="id"
                label="name"
                placeholder="Please select a role"
                open-direction="bottom"
                :searchable="true"
                :clear-on-select="false"
                :close-on-select="true"
                :max-height="600"
                :show-no-results="false"
                :hide-selected="false"
                :options="muteRoles"
                :allow-empty="true"
              >
                <template slot="singleLabel" slot-scope="props">
                  <span class="option__desc">
                    <font-awesome-icon style="color:#72767d" icon="user-tag" />
                    <span class="option__title ml-2">{{
                      props.option.name
                    }}</span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span
                      class="option__title"
                      :style="{ color: '#' + intToHex(props.option.color) }"
                      >{{ props.option.name }}</span
                    >
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group label="Whitelisted Channels">
              <multiselect
                v-model="channel_wl"
                :options="groupedChannels"
                group-values="channels"
                group-label="category"
                :group-select="false"
                :multiple="true"
                label="name"
                track-by="id"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag channelpicker__tag"
                    :style="{ borderColor: '#eee' }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon channelpicker_icon"
                      :style="{ backgroundColor: '#eee' }"
                    ></i>
                    <font-awesome-icon
                      style="color:#999;margin-right:3px;"
                      icon="hashtag"
                    />
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Whitelisted Roles"
              label-for="movement_channel_select"
            >
              <multiselect
                v-model="role_wl"
                :options="roles"
                :multiple="true"
                label="name"
                track-by="id"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag"
                    :style="{
                      'border-color': option
                        ? '#' + option.color.toString(16)
                        : '#eeaaee'
                    }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon fakediscord"
                      :style="{
                        'background-color':
                          '#' + intToHex(option.color || 12172222)
                      }"
                    ></i>
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div
                    :style="{ color: parseColor(props.option) }"
                    class="option__desc"
                  >
                    <span class="option__title">{{ props.option.name }}</span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-form-group
              label="Media only channels"
              label-for="movement_channel_select"
            >
              <multiselect
                v-model="media_channels"
                :options="groupedChannels"
                group-values="channels"
                group-label="category"
                :group-select="false"
                :multiple="true"
                label="name"
                track-by="id"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag channelpicker__tag"
                    :style="{ borderColor: '#eee' }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon channelpicker_icon"
                      :style="{ backgroundColor: '#eee' }"
                    ></i>
                    <font-awesome-icon
                      style="color:#999;margin-right:3px;"
                      icon="hashtag"
                    />
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon
                        style="color:#72767d"
                        :icon="
                          !props.option['$isLabel'] ? 'hashtag' : 'chevron-down'
                        "
                      />
                      {{ props.option.name || props.option.$groupLabel.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
            <b-row class="mr-auto">
              <b-col cols="auto" class="mr-auto p-2 mx-2">
                <label for="reassign_toggle_switch">
                  <p
                    v-b-tooltip.hover
                    title="Does not delete images, text, or video"
                    style="margin-right:0px;"
                  >
                    Delete scary files
                  </p>
                </label>
              </b-col>
              <b-col cols="auto" class="p-2">
                <c-switch
                  class="mx-1"
                  id="reassign_toggle_switch"
                  color="info"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="delete_files"
                />
              </b-col>
            </b-row>
            <div slot="footer">
              <b-button variant="primary" v-on:click="basic_settings"
                >Save</b-button
              >
            </div>
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-share"></i> Invitespam</div>
          <h4 class="smalltitle">Bad Invites</h4>
          <!-- <p
            v-if="invitespam_ratelimit[0] == 0 || invitespam_ratelimit[1] == 0"
            class="teenytinytitle mt-1"
          >
            Disabled
            <font-awesome-icon
              title="One or both of your values are set to 0, this will never trigger."
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
            />
          </p> -->
          <hr />
          <div>
            <b-row class="mr-auto">
              <b-col cols="auto" class="mr-auto p-2 mx-2">
                <label for="reassign_toggle_switch">
                  <p style="margin-right:0px;">
                    Invitespam
                  </p>
                </label>
              </b-col>
              <b-col cols="auto" class="p-2">
                <c-switch
                  class="mx-1"
                  id="reassign_toggle_switch"
                  color="info"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="isInviteSpamEnabled"
                />
              </b-col>
            </b-row>
            <b-row class="mr-auto" v-if="isInviteSpamEnabled">
              <b-col cols="auto" class="mr-auto p-2 mx-2">
                <label for="reassign_toggle_switch">
                  <p style="margin-right:0px;">
                    Norole mode
                  </p>
                </label>
              </b-col>
              <b-col cols="auto" class="p-2">
                <c-switch
                  class="mx-1"
                  id="reassign_toggle_switch"
                  color="info"
                  label
                  variant="pill"
                  v-bind="labelIcon"
                  v-model="isNoroleModeEnabled"
                />
              </b-col>
            </b-row>
          <h6>Punishments</h6>

          <multiselect
            v-model="invitespam_punishment"
            :options="invitespam_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div v-if="do_invitespam_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-model="invitespam_time"
                v-on:keydown="handleNonDecimalInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="invitespam_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <hr />

          </div>
          <!-- <b-form-group label="Invitespam mode" label-for="invitespam_mode">
            <b-form-select
              id="invitespam_mode"
              v-model="invitespam_mode"
              value="Please select"
            >
              <option :value="0">Blacklist</option>
              <option :value="1">Whitelist</option>
              <option :value="2">No roles</option>
            </b-form-select>
          </b-form-group>
          <div v-if="invitespam_mode == 0">
            <h6>Blacklisted server ids</h6>
            <vue-tags-input
              v-model="invitespam_tag_bl"
              :tags="invitespam_tags_bl"
              @tags-changed="newTags => (invitespam_tags_bl = newTags)"
              :allow-edit-tags="true"
              :max-tags="50"
              :maxlength="5000"
              style="background-color: #4f5b63;max-width: none; width: 100%;"
              :validation="id_validation"
              placeholder="Add invite"
            />
            <p>
              Invites pointing to servers on this blacklist count as an
              infraction.
            </p>
          </div>
          <div v-else>
            <h6>Whitelisted server ids</h6>
            <vue-tags-input
              v-model="invitespam_tag_wl"
              :tags="invitespam_tags_wl"
              @tags-changed="newTags => (invitespam_tags_wl = newTags)"
              :allow-edit-tags="true"
              :max-tags="50"
              :maxlength="5000"
              style="background-color: #4f5b63;max-width: none; width: 100%;"
              :validation="id_validation"
              placeholder="Add invite"
            />
            <p>
              Invites not pointing to servers on this whitelist count as an
              infraction.
            </p>
          </div>

          <hr />
          <h6>Punishments</h6>

          <multiselect
            v-model="invitespam_punishment"
            :options="invitespam_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>

          <div v-if="do_invitespam_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-model="invitespam_time"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="invitespam_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <hr />
          <h6>Punish after</h6>
          <b-row>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                v-model="invitespam_ratelimit[0]"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Invites in</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                v-model="invitespam_ratelimit[1]"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Seconds</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row> -->

          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="invitespam_settings"
              :disabled="
                do_invitespam_punishment_minute_enable
                  ? invitespam_time < 1
                  : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-bell"></i> Mentionspam</div>
          <h4 class="smalltitle">Mentionspam</h4>
          <p
            v-if="
              mentionspam_ratelimit[0] == 0 || mentionspam_ratelimit[1] == 0
            "
            class="teenytinytitle mt-1"
          >
            Disabled
            <font-awesome-icon
              title="One or both of your values are set to 0, this will never trigger."
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
            />
          </p>
          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="mentionspam_punishment"
            :options="mentionspam_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div v-if="do_mentionspam_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-on:keydown="handleNonDecimalInput"
                v-model="mentionspam_time"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="mentionspam_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <hr />
          <h6>Punish after</h6>
          <b-row>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="mentionspam_ratelimit[0]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1"
                  >Mentions in</b-input-group-text
                >
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="mentionspam_ratelimit[1]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Seconds</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row>

          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="mentionspam_settings"
              :disabled="
                do_mentionspam_punishment_minute_enable
                  ? mentionspam_time < 1
                  : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-picture"></i> Attachmentspam</div>
          <h4 class="smalltitle">Attachmentspam</h4>
          <p
            v-if="
              attachmentspam_ratelimit[0] == 0 ||
                attachmentspam_ratelimit[1] == 0
            "
            class="teenytinytitle mt-1"
          >
            Disabled
            <font-awesome-icon
              title="One or both of your values are set to 0, this will never trigger."
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
            />
          </p>
          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="attachmentspam_punishment"
            :options="attachmentspam_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div v-if="do_attachmentspam_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-on:keydown="handleNonDecimalInput"
                v-model="attachmentspam_time"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="attachmentspam_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <hr />
          <h6>Punish after</h6>
          <b-row>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="attachmentspam_ratelimit[0]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Files in</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="attachmentspam_ratelimit[1]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Seconds</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row>
          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="attachmentspam_settings"
              :disabled="
                do_attachmentspam_punishment_minute_enable
                  ? attachmentspam_time < 1
                  : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
      </b-col>

      <b-col sm="12" md="6">
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-link"></i> Linkspam</div>
          <h4 class="smalltitle">Bad Links</h4>
          <p
            v-if="linkspam_ratelimit[0] == 0 || linkspam_ratelimit[1] == 0"
            class="teenytinytitle mt-1"
          >
            Disabled
            <font-awesome-icon
              title="One or both of your values are set to 0, this will never trigger."
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
            />
          </p>
          <hr />
          <b-form-group label="Linkspam mode" label-for="linkspam_mode">
            <b-form-select
              id="linkspam_mode"
              v-model="linkspam_mode"
              value="Please select"
            >
              <option :value="0">Blacklist</option>
              <option :value="1">Whitelist</option>
              <option :value="2">No roles</option>
            </b-form-select>
          </b-form-group>
          <div v-if="linkspam_mode == 0">
            <h6>Blacklisted domains</h6>
            <vue-tags-input
              v-model="linkspam_tag_bl"
              :tags="linkspam_tags_bl"
              @tags-changed="newTags => (linkspam_tags_bl = newTags)"
              :allow-edit-tags="true"
              :max-tags="50"
              :maxlength="5000"
              style="background-color: #4f5b63;max-width: none; width: 100%;"
              :validation="validation"
              placeholder="Add link"
            />
            <p>Links posted on this blacklist count as an infraction.</p>
          </div>
          <div v-else>
            <h6>Whitelisted domains</h6>
            <vue-tags-input
              v-model="linkspam_tag_wl"
              :tags="linkspam_tags_wl"
              @tags-changed="newTags => (linkspam_tags_wl = newTags)"
              :allow-edit-tags="true"
              :max-tags="50"
              :maxlength="5000"
              style="background-color: #4f5b63;max-width: none; width: 100%;"
              :validation="validation"
              placeholder="Add link"
            />
            <p>Links not on this whitelist count as an infraction.</p>
          </div>

          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="linkspam_punishment"
            :options="linkspam_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div
            v-if="do_linkspam_punishment_punishment_minute_enable"
            class="my-2"
          >
            <b-input-group>
              <b-form-input
                type="number"
                min="1"
                v-on:keydown="handleNonDecimalInput"
                v-model="linkspam_time"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="linkspam_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <hr />
          <h6>Punish after</h6>
          <b-row>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="linkspam_ratelimit[0]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Links in</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                placeholder="0"
                v-model="linkspam_ratelimit[1]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Seconds</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row>
          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="linkspam_settings"
              :disabled="
                do_linkspam_punishment_punishment_minute_enable
                  ? linkspam_time < 1
                  : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-bubble"></i> Bad words</div>
          <h4 class="smalltitle">Bad Words</h4>
          <hr />
          <p>Case insensitive, looks for substrings, ignores punctuation.</p>
          <vue-tags-input
            v-model="censor_tag"
            :tags="censor_tags"
            @tags-changed="newTags => (censor_tags = newTags)"
            :allow-edit-tags="true"
            :max-tags="50"
            :maxlength="5000"
            style="background-color: #4f5b63;max-width: none; width: 100%;"
            placeholder="Add word"
          />
          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="censor_punishment"
            :options="bad_word_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div v-if="do_censor_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                v-model="censor_time"
                min="1"
                v-on:keydown="handleNonDecimalInput"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="censor_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="censor_settings"
              :disabled="
                do_censor_punishment_minute_enable ? censor_time < 1 : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header">
            <i class="icon-bubbles"></i>
            Spam
          </div>
          <h4 class="smalltitle">Spam</h4>
          <p
            v-if="slowmode_ratelimit[0] == 0 || slowmode_ratelimit[1] == 0"
            class="teenytinytitle mt-1"
          >
            Disabled
            <font-awesome-icon
              title="One or both of your values are set to 0, this will never trigger."
              style="color:#888"
              :icon="['fas', 'question-circle']"
              class="mx-2"
            />
          </p>
          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="slowmode_punishment"
            :options="slowmode_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div
            v-if="do_slowmodepunishment_punishment_minute_enable"
            class="my-2"
          >
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-on:keydown="handleNonDecimalInput"
                v-model="slowmode_time"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="slowmode_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>
          <hr />
          <h6>Punish after</h6>
          <b-row>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="slowmode_ratelimit[0]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-0"
                  >Messages in</b-input-group-text
                >
              </b-input-group-append>
            </b-input-group>
            <b-input-group class="col-sm-6 my-1">
              <b-form-input
                type="number"
                placeholder="0"
                min="0"
                v-on:keydown="handleOnlyNumberkeyInput"
                v-model="slowmode_ratelimit[1]"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="ml-0">Seconds</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row>

          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="slowmode_settings"
              :disabled="
                do_slowmodepunishment_punishment_minute_enable
                  ? slowmode_time < 1
                  : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-close"></i> Warn threshold</div>
          <h4 class="smalltitle">Warn threshold</h4>
          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="warn_punishment"
            :options="warn_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div v-if="do_warn_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-on:keydown="handleNonDecimalInput"
                v-model="warn_time"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="warn_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>

          <hr />
          <h6>Punish after</h6>
          <b-input-group>
            <b-form-input
              type="number"
              placeholder="0"
              min="0"
              v-on:keydown="handleNonDecimalInput"
              v-model="warn_threshold"
            ></b-form-input>
            <b-input-group-append>
              <b-input-group-text class="mr-1">Warnings</b-input-group-text>
            </b-input-group-append>
          </b-input-group>

          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="warn_settings"
              :disabled="
                do_warn_punishment_minute_enable ? warn_time < 1 : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
        <b-card v-if="!loading">
          <div slot="header"><i class="icon-close"></i> Caps</div>
          <h4 class="smalltitle">Caps threshold</h4>
          <hr />
          <h6>Punishments</h6>
          <multiselect
            v-model="caps_punishment"
            :options="caps_picker"
            :multiple="true"
            label="name"
            track-by="value"
          >
            <template slot="tag" slot-scope="{ option, search, remove }">
              <span
                class="multiselect__tag channelpicker__tag"
                :style="{ borderColor: '#eee' }"
              >
                <i
                  aria-hidden="true"
                  tabindex="1"
                  @keydown.enter.prevent="remove(option)"
                  @mousedown.prevent="remove(option)"
                  class="multiselect__tag-icon channelpicker_icon"
                  :style="{ backgroundColor: '#eee' }"
                ></i>
                <span class="defaultcursor" v-text="option.name"></span>
              </span>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title">{{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
          <div v-if="do_caps_punishment_minute_enable" class="my-2">
            <b-input-group>
              <b-form-input
                type="number"
                placeholder="1"
                min="1"
                v-on:keydown="validateInput"
                v-model="caps_time"
              ></b-form-input>
              <b-input-group-append>
                <b-input-group-text class="mr-1">Minutes</b-input-group-text>
              </b-input-group-append>
            </b-input-group>
            <p v-if="caps_time < 1" class="text-danger">
              Min value for minutes is 1.
            </p>
          </div>

          <hr />
          <h6>Punish after</h6>
          <b-input-group>
            <b-form-input
              type="number"
              placeholder="0"
              min="0"
              v-on:keydown="handleNonDecimalInput"
              v-model="caps_threshold"
            ></b-form-input>
            <b-input-group-append>
              <b-input-group-text class="mr-1">Caps limit</b-input-group-text>
            </b-input-group-append>
          </b-input-group>

          <div slot="footer">
            <b-button
              variant="primary"
              v-on:click="caps_settings"
              :disabled="
                do_caps_punishment_minute_enable ? caps_time < 1 : false
              "
              >Save</b-button
            >
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

Vue.use(VueNotifications, toast_options);

export default {
  name: "Automod",
  components: {
    cSwitch,
    VueTagsInput,
    Multiselect
  },
  data: function() {
    return {
      roles: [],
      channels: [],
      censor_tag: "",
      censor_tags: [],
      censor_punishment: [],
      isInviteSpamEnabled: true,
      isNoroleModeEnabled: true,
      censor_time: 0,
      linkspam_tag_wl: "",
      linkspam_tags_wl: [],
      linkspam_tag_bl: "",
      linkspam_tags_bl: [],
      linkspam_punishment: [],
      linkspam_time: 0,
      linkspam_ratelimit: [0, 0],
      linkspam_mode: 0,
      invitespam_tag_wl: "",
      invitespam_tags_wl: [],
      invitespam_tag_bl: "",
      invitespam_tags_bl: [],
      invitespam_punishment: [],
      invitespam_time: 0,
      invitespam_ratelimit: [0, 0],
      invitespam_mode: 0,
      mentionspam_punishment: [],
      mentionspam_time: 0,
      mentionspam_ratelimit: [0, 0],
      slowmode_punishment: [],
      slowmode_time: 0,
      slowmode_ratelimit: [0, 0],
      attachmentspam_punishment: [],
      attachmentspam_time: 0,
      attachmentspam_ratelimit: [0, 0],
      warn_punishment: [],
      warn_time: 1,
      warn_threshold: 0,
      caps_punishment: [],
      caps_time: 1,
      caps_threshold: 0,
      delete_files: false,
      log_channel: null,
      drama_channel: null,
      role_wl: [],
      channel_wl: [],
      media_channels: [],
      muterole: null,
      loading: true,

      punishments: [
        { name: "Delete message", value: 1 },
        { name: "Warn", value: 2 },
        { name: "Tempmute", value: 4 },
        { name: "Mute", value: 8 },
        { name: "Kick", value: 16 },
        { name: "Tempban", value: 32 },
        { name: "Ban", value: 64 },
        { name: "Timeout", value: 1024 },
        { name: "Post to drama channel (premium only)", value: 128 },
        { name: "Send message in channel", value: 256 },
        { name: "Send message to offender", value: 512 }
      ],
      validation: [
        {
          classes: "website",
          rule: tag =>
            !tag.text.match(/(?:https?:\/\/)?([\w_-]+(?:(?:\.[\w_-]+)+))/),
          disableAdd: true
        }
      ],
      id_validation: [
        {
          classes: "discord_id",
          rule: tag =>
            !tag.text.match(
              /(discord(?:app\.com|\.gg)(?:\/invite)?\/?(.[A-z0-9]{1,32})|\d{10,30})/
            ),
          disableAdd: true
        }
      ],
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      }
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/automod")
      .then(r => {});
  },
  methods: {
    basic_settings() {
      return this.put_settings({
        log_channel: this.log_channel ? this.log_channel.id : null,
        drama_channel: this.drama_channel ? this.drama_channel.id : null,
        role_wl: this.role_wl.map(x => x.id),
        channel_wl: this.channel_wl.map(x => x.id),
        delete_files: this.delete_files,
        media_channels: this.media_channels.map(x => x.id),
        muterole: this.muterole ? this.muterole.id : null
      });
    },
    censor_settings() {
      return this.put_settings({
        bad_words: this.censor_tags.map(x => x.text),
        bad_word_punishment: this.punish_to_value(this.censor_punishment),
        bad_word_time: this.censor_time
      });
    },
    warn_settings() {
      return this.put_settings({
        warn_threshold: this.warn_threshold,
        warn_punishment: this.punish_to_value(this.warn_punishment),
        warn_time: this.warn_time
      });
    },
    caps_settings() {
      return this.put_settings({
        caps_threshold: this.caps_threshold,
        caps_punishment: this.punish_to_value(this.caps_punishment),
        caps_time: this.caps_time
      });
    },
    attachmentspam_settings() {
      return this.put_settings({
        attachmentspam_punishment: this.punish_to_value(
          this.attachmentspam_punishment
        ),
        attachmentspam_time: this.attachmentspam_time,
        attachmentspam_ratelimit: this.attachmentspam_ratelimit
      });
    },
    mentionspam_settings() {
      return this.put_settings({
        mentionspam_punishment: this.punish_to_value(
          this.mentionspam_punishment
        ),
        mentionspam_time: this.mentionspam_time,
        mentionspam_ratelimit: this.mentionspam_ratelimit
      });
    },
    slowmode_settings() {
      return this.put_settings({
        slowmode_punishment: this.punish_to_value(this.slowmode_punishment),
        slowmode_time: this.slowmode_time,
        slowmode_ratelimit: this.slowmode_ratelimit
      });
    },
    linkspam_settings() {
      return this.put_settings({
        linkspam_tags_wl: this.linkspam_tags_wl.map(x => x.text),
        linkspam_tags_bl: this.linkspam_tags_bl.map(x => x.text),
        linkspam_punishment: this.punish_to_value(this.linkspam_punishment),
        linkspam_time: this.linkspam_time,
        linkspam_ratelimit: this.linkspam_ratelimit,
        linkspam_mode: this.linkspam_mode
      });
    },
    invitespam_settings() {
      return this.put_settings({
        isNoroleModeEnabled: this.isNoroleModeEnabled,
        isInviteSpamEnabled: this.isInviteSpamEnabled,
        // invitespam_tags_wl: this.invitespam_tags_wl.map(x => x.text),
        // invitespam_tags_bl: this.invitespam_tags_bl.map(x => x.text),
        invitespam_punishment: this.punish_to_value(this.invitespam_punishment),
        invitespam_time: this.invitespam_time
        // invitespam_ratelimit: this.invitespam_ratelimit,
        // invitespam_mode: this.invitespam_mode
      });
    },
    put_settings(obj) {
      this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/automod", obj)
        .then(this.showSuccessMsg());
    },
    value_to_options(val) {
      return this.punishments.filter(x => (x.value & val) != 0);
    },
    LightenDarkenColor(col, amt) {
      return (
        ((col & 0x0000ff) + amt) |
        ((((col >> 8) & 0x00ff) + amt) << 8) |
        (((col >> 16) + amt) << 16)
      ).toString(16);
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },
    punish_to_value(settings) {
      if (settings.length == 0) {
        return 0;
      }
      return settings
        .map(item => item.value)
        .reduce((prev, next) => prev + next);
    },
    punishment_parser(settings) {
      if (settings.length == 0) {
        return this.punishments;
      }

      var total = settings
        .map(item => item.value)
        .reduce((prev, next) => prev + next);
      var in_common = 1148 & total;

      if (!in_common) {
        return this.punishments;
      }

      return this.punishments.filter(
        x => (1148 & x.value) == 0 || x.value == in_common
      );
    },
    validateInput(event) {
      const key = event.key;
      if (key === "-" || key === "+" || key === "e" || key === "E"|| key ===".") {
        event.preventDefault();
      }
    },
  },
  created: function() {
    this.$Progress.start();
    this.censor_punishment = this.value_to_options(7);
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/automod")
      .then(r => {
        this.roles = r.data.roles;
        this.channels = r.data.channels;

        this.censor_tags = createTags(r.data.settings.bad_words || []);
        this.censor_punishment = this.value_to_options(
          r.data.settings.word_punishment[0]
        );
        this.censor_time = r.data.settings.word_punishment[1] / 60;

        var ip = r.data.settings.invite_punishment || [0, 0];
        this.isNoroleModeEnabled = r.data.settings.isNoroleModeEnabled;
        this.isInviteSpamEnabled = r.data.settings.isInviteSpamEnabled;
        // this.invitespam_tags_wl = createTags(r.data.settings.invite_wl || []);
        // this.invitespam_tags_bl = createTags(r.data.settings.invite_bl || []);
        this.invitespam_punishment = this.value_to_options(ip[0]);
        this.invitespam_time = ip[1] / 60;
        // this.invitespam_ratelimit = r.data.settings.invite_slowmode || [0, 0];
        // this.invitespam_mode = r.data.settings.invite_setting;

        var lp = r.data.settings.link_punishment || [0, 0];
        this.linkspam_tags_wl = createTags(r.data.settings.link_wl || []);
        this.linkspam_tags_bl = createTags(r.data.settings.link_bl || []);
        this.linkspam_punishment = this.value_to_options(lp[0]);
        this.linkspam_time = lp[1] / 60;
        this.linkspam_ratelimit = r.data.settings.link_slowmode || [0, 0];
        this.linkspam_mode = r.data.settings.link_setting;

        let log_chn = r.data.settings.log_channel || null;
        this.log_channel = this.channels.find(c => {
          return c.id === log_chn;
        });
        let council = r.data.settings.council_channel || null;
        this.drama_channel = this.channels.find(c => {
          return c.id === council;
        });
        (this.role_wl =
          this.roles.filter(x => r.data.settings.role_wl.includes(x.id)) || []), //r.data.settings.role_wl;
          (this.channel_wl =
            this.channels.filter(x =>
              r.data.settings.channel_wl.includes(x.id)
            ) || []);
        this.delete_files = r.data.settings.delete_files;
        this.media_channels =
          this.channels.filter(x =>
            r.data.settings.media_channels.includes(x.id)
          ) || [];
        let muteId = r.data.muterole || null;
        this.muterole = this.muteRoles.find(r => {
          return r.id == muteId;
        });
        if (muteId !== null && !this.muterole) {
          this.muterole = { name: "Invalid role selected", id: muteId };
        }

        var wp = r.data.settings.warn_punishment || [0, 0];
        this.warn_punishment = this.value_to_options(wp[0]);
        this.warn_time = wp[1] / 60;
        this.warn_threshold = r.data.settings.warn_threshold || 0;

        var cp = r.data.settings.caps_punishment || [0, 0];
        this.caps_punishment = this.value_to_options(cp[0]);
        this.caps_time = cp[1] / 60;
        this.caps_threshold = r.data.settings.caps_threshold || 0;

        var ap = r.data.settings.attachment_punishment || [0, 0];
        this.attachmentspam_punishment = this.value_to_options(ap[0]);
        this.attachmentspam_time = ap[1] / 60;
        this.attachmentspam_ratelimit = r.data.settings.attachmentspam || [
          0,
          0
        ];

        var mp = r.data.settings.mentionspam_punishment || [0, 0];
        this.mentionspam_punishment = this.value_to_options(mp[0]);
        this.mentionspam_time = mp[1] / 60;
        this.mentionspam_ratelimit = r.data.settings.mentionspam || [0, 0];

        var sp = r.data.settings.slowmode_punishment || [0, 0];
        this.slowmode_punishment = this.value_to_options(sp[0]);
        this.slowmode_time = sp[1] / 60;
        this.slowmode_ratelimit = r.data.settings.slowmode || [0, 0];
        this.loading = false;
        this.$Progress.finish();
      });
  },
  computed: {
    do_invitespam_punishment_minute_enable() {
      return this.invitespam_punishment.find(x => (1060 & x.value) != 0);
    },
    do_mentionspam_punishment_minute_enable() {
      return this.mentionspam_punishment.find(x => (1060 & x.value) != 0);
    },
    do_attachmentspam_punishment_minute_enable() {
      return this.attachmentspam_punishment.find(x => (1060 & x.value) != 0);
    },
    do_linkspam_punishment_punishment_minute_enable() {
      return this.linkspam_punishment.find(x => (1060 & x.value) != 0);
    },
    do_slowmodepunishment_punishment_minute_enable() {
      return this.slowmode_punishment.find(x => (1060 & x.value) != 0);
    },
    do_censor_punishment_minute_enable() {
      return this.censor_punishment.find(x => (1060 & x.value) != 0);
    },
    do_warn_punishment_minute_enable() {
      return this.warn_punishment.find(x => (1060 & x.value) != 0);
    },
    do_caps_punishment_minute_enable() {
      return this.caps_punishment.find(x => (1060 & x.value) != 0);
    },

    bad_word_picker() {
      return this.punishment_parser(this.censor_punishment);
    },
    linkspam_picker() {
      return this.punishment_parser(this.linkspam_punishment);
    },
    invitespam_picker() {
      return this.punishment_parser(this.invitespam_punishment);
    },
    mentionspam_picker() {
      return this.punishment_parser(this.mentionspam_punishment);
    },
    slowmode_picker() {
      return this.punishment_parser(this.slowmode_punishment);
    },
    attachmentspam_picker() {
      return this.punishment_parser(this.attachmentspam_punishment);
    },
    warn_picker() {
      return this.punishment_parser(this.warn_punishment).filter(
        x => (x.value <= 128 && x.value > 2) || x.value === 1024
      );
    },
    caps_picker() {
      return this.punishment_parser(this.caps_punishment).filter(
        x => (x.value <= 128 && x.value > 2) || x.value === 1024
      );
    },
    muteRoles() {
      return [{ name: "No muterole selected", id: null }, ...this.roles];
    },
    groupedChannels() {
      let groups = [
        {
          category: {
            id: null,
            name: "Uncategorized"
          },
          channels: []
        }
      ];
      let currentGroup = groups[0];
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          currentGroup = { category: chn, channels: [] };
          groups.push(currentGroup);
        }
      }
      for (let chn of this.channels.sort((a, b) => a.position - b.position)) {
        if (chn.type === 4) {
          continue;
        }
        currentGroup = groups.find(g => {
          return g.category.id == chn.parent_id;
        });
        if (!currentGroup) {
          continue;
        }
        currentGroup.channels.push(chn);
      }
      return groups;
    }
  }
};
</script>
<style lang="css">
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #606bb6;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #5e6bbb;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #5e6bbb;
  color: #5e6bbb;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}
</style>
<style src="spinkit/spinkit.min.css"></style>
